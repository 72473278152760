<template>
	<div class="flex" style="flex-direction: column;height: 100%;">
		
		<div class="tableModule">
			<div class="title">
				<div class="title-left">
					<i class="el-icon-s-operation"></i>
					<span>报警列表</span>
				</div>
			</div>
			<div class="tableContainer">
				<div class="table" ref="tableContainer">
					<div>
						<el-table
							ref="usertable"
							:data="tableData.records"
							border
							:height="tableHeight"
							:header-cell-style="{ 'background-color': '#f9fafc' }"
							:cell-style="{ padding: 0 }"
							style="margin-bottom: 20px;"
						>
							<el-table-column align="center" label="序号" show-overflow-tooltip>
								<template slot-scope="scope">
									<div style="height: 40px;line-height: 40px;">{{ scope.$index + 1 }}</div>
								</template>
							</el-table-column>
							<el-table-column prop="ent_name" align="center" label="企业名称" show-overflow-tooltip></el-table-column>
							<el-table-column prop="unit_name" align="center" label="单体名称" show-overflow-tooltip></el-table-column>
							<el-table-column prop="meas_name" align="center" label="测点名称" show-overflow-tooltip></el-table-column>
							<el-table-column prop="ip" align="center" label="IP" show-overflow-tooltip></el-table-column>
							<el-table-column prop="port" align="center" label="端口" show-overflow-tooltip></el-table-column>
							<el-table-column prop="chan" align="center" label="通道" show-overflow-tooltip></el-table-column>
						</el-table>
					</div>
				</div>
				<div class="pagination">
					<el-pagination
						style="float: right;"
						@current-change="handleCurrentChange"
						:current-page.sync="searchParm.current"
						:page-size="searchParm.size"
						layout="total, prev, pager, next,jumper"
						:total="tableData.total"
					></el-pagination>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	data() {
		return {
			tableHeight: 0,
			tableData: {
				records: []
			},
			searchParm: {
				size: 20,
				current: 1
			},
			ents: [],
		};
	},
	methods: {
		//自适应表格高度
		calHeight() {
			this.$nextTick(() => {
				const rect = this.$refs.tableContainer.getBoundingClientRect();
				this.tableHeight = rect.height;
			});
		},
		//获取企业列表
		getEnts() {
			this.$get('/backend-api/sys/ent/list', {
				size: 99
			}).then(res => {
				if (res.code == 1000) {
					this.ents = res.data.records;
				} else {
					this.$message.error(res.msg);
				}
			});
		},
		//清空搜索
		clearSearch() {
			this.searchParm = {
				size: 20,
				current: 1
			};
		},
		//展示数据
		list() {
			this.$get('/backend-api/oil/sta/sen/list', this.searchParm).then(res => {
				if (res.code == 1000) {
					this.tableData = res.data;
				} else {
					this.$message.error(res.msg);
				}
			});
		},
		

		//分页选择页面回调
		handleCurrentChange(val) {
			this.searchParm.current = val;
			this.list();
		}
	},
	mounted() {
		this.list();
		this.getEnts();
		this.calHeight();
		window.addEventListener('resize', this.calHeight);
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.calHeight);
		this.showlookListDialog = false;
	}
};
</script>
<style lang="scss" scoped>
@import '@style/userCenter.scss';
</style>
